<template>
  <div class="home-banner">
    <div class="gallery" v-if="photos && photos.length" v-show="!loading">
      <VueSlickCarousel v-bind="settings" class="gallery-carousel">
        <div v-for="(photo, index) in photos" :key="index">
          <div class="gallery-item-card">
            <img
              :src="photo.original"
              :alt="'Image ' + (index + 1)"
              class="gallery-image"
            />
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
// Import VueSlickCarousel and styles
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const api = "https://balitripcenter.com/api/banner";

export default {
  name: "Banner",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      photos: [], // Gallery photos
      settings: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1, // Show one slide at a time
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false, // Hide arrows
        dots: false, // Hide dots
      },
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          this.photos = response.data.items; // Use all items in the response
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
  },
};
</script>

<style scoped>
.home-banner {
  margin-bottom: 20px;
  /* width: 100vw; */
  width: 100%;
  /* max-width: 100vw; */
  max-width: 100%;
  /* position: relative; */
  /* left: -12px; */
}
.gallery-carousel {
  width: 100%;
  max-width: 100%; /* Optional: Set a max width for the slideshow */
  margin: 0 auto;
  position: relative;
}

.gallery-item-card {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gallery-image {
  width: 100%;
  object-fit: cover; /* Maintain aspect ratio and crop to fit the container */
  border-radius: 10px; /* Optional: Add rounded corners to the images */
}
</style>
