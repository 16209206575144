<template>
  <v-card
    class="black--text"
    :to="{
      name: 'activity',
      params: { slug: post.slug, type: post.type.slug },
    }"
  >
    <v-container fluid grid-list-lg pa-2>
      <v-layout row>
        <v-flex xs5>
          <v-img
            :src="post.images[0].thumbnail"
            :alt="post.images[0].thumbnail"
            height="155px"
            width="146px"
            style="border-radius: 5px !important"
          >
          </v-img>
        </v-flex>
        <v-flex xs7 pa-1 style="padding: 20px 10px !important" >
          <div>
            <span
              style="
                font-size: 14px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ post.name }}</span
            >
            <v-flex d-inline-flex pa-0 align-center>
              <v-rating
                v-model="post.rating"
                background-color="gray"
                color="#FE724C"
                dense
                size="15"
              ></v-rating>
              <span style="margin-left: 4px; font-size: 12px">{{
                post.comments_count
              }}</span>
            </v-flex>
            <v-flex xs12 class="rigth" pr-2>
              <span style="font-size: 12px; font-weight: 400; color: #303135">{{
                $t("from")
              }}</span>
              <div>
                <span style="font-size: 14px !important; font-weight: 600">
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</span
                >
                <span style="font-size: 14px !important; font-weight: 600">
                  {{
                    $root.$i18n.locale == "id"
                      ? post.price_idr
                      : post.price_usd | fm
                  }}</span
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.rigth {
  text-align: right !important;
}
.top {
  vertical-align: top;
}
</style>
