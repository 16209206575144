<template>
<div>
  <v-card
    class="rounded mb-2"
    elevation="4"
    :to="{ name: 'destination',
     params: { slug: post.slug, regency: post.regency.slug } }"
  >
    <v-img
      :src="post.images[0].thumbnail"
      :alt="post.images[0].thumbnail"
      height="150px"
      width="100%"
    >
      <v-container fill-height fluid class="overlay">
        <v-layout fill-height>
          <v-flex pa-0 mt-8 xs12 align-end flexbox text-center style="color: white;position: relative; z-index: 3;">
            <h2 style="color:#fff !important;">{{ post.name }}</h2>
            <small class="subtitle-2">{{ post.location }}</small
            ><br />
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
  </v-card>
</div>
</template>

<script>
export default {
  props: ["post"],
};
</script>

<style scoped>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.rounded {
  border-radius: 14px !important;
}

.overlay {
  position: relative;
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: 1;
}

.v-layout {
  position: relative;
  z-index: 2;
}
</style>
