<template>
  <center>
    <br />
    <img
      src="@/assets/images/about-bali.png"
      height="auto"
      width="90%"
      alt="transport"
    />
    <div class="text-h6">{{ $t('siapa-kami') }}</div>
    <div class="text-paragraph">
      {{ $t('balitripcenter-adalah-operator-iokal-bali') }} </div><br/>
  </center>
</template>

<script>
export default {
  
}
</script>

<style>
.divider {
  width: 200px;
  height: 4px;
  background-color: orange;
  margin-bottom: 20px;
}

.text-h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700 !important;
}

.text-paragraph {
  font-size: 14px !important;
}
</style>