<template>
  <v-container fluid mb-1 pa-0 mt-4>
    <v-layout row wrap>
      <v-flex xs12 class="text-center">
        <div>
          <span
            style="font-size: 16px; font-weight: bold; color: #4CAF50; text-transform: uppercase; margin-bottom: 20px;"
          >{{ $t('average-rating') }}</span>
          <span
            style="font-size: 16px;font-weight: 600; margin: 8px 0;display: block;"
          >{{ $t('avg', [avg]) }}</span>
          <div style="display: inline-block;margin-bottom: 24px;">
            <v-rating
              v-model="avg"
              background-color="gray"
              color="#ff6100"
              dense
              size="20"
            ></v-rating>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["avg"],
};
</script>
<style scoped>
</style>

