import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './plugins/i18n'
import VueAgile from 'vue-agile'
import VueScrollTo from 'vue-scrollto'

import NavHeader from './components/NavHeader'
import ActivityCard from './components/activity/ActivityCard'
import ActivityCardDetail from './components/activity/ActivityCardDetail'
import PriceDetail from './components/activity/PriceDetail'
import ActivityDetail from './components/activity/ActivityDetail'
import DestinationCard from './components/destination/DestinationCard'
import TransportCard from './components/transport/TransportCard'
import TransportDetail from './components/transport/TransportDetail'
import Faq from './components/others/Faq'
import CommentItem from './components/comment/CommentItem'
import CommentHeader from './components/comment/CommentHeader'
import CommentOverview from './components/comment/CommentOverview'
import MainCard from './components/home/HomeMainCategory'
import HomeFeature from './components/home/HomeFeature'
import HomeDrivers from './components/home/HomeDrivers'
import HomePopulars from './components/home/activity/HomePopulars'
import HomeWaterSport from './components/home/activity/HomeWaterSport'
import HomeRecommendations from './components/home/activity/HomeRecommendations'
import HomeReviews from './components/home/activity/HomeReviews'
import HomeReviewCard from './components/home/activity/HomeReviewCard'
import HomeTour from './components/home/activity/HomeTour'
import HomeActivitiyCard from './components/home/activity/HomeActivityCard'
import HomeDestinations from './components/home/destination/HomeDestinations'
import HomeDestinationCard from './components/home/destination/HomeDestinationCard'
import HomeTips from './components/home/tips/HomeTips'
import HomeTipsCard from './components/home/tips/HomeTipsCard'
import HomeCategories from './components/home/HomeCategories'
import ActivityRecommendation from './components/home/activity/ActivityRecommendation.vue'
import activityPopular from './components/home/activity/activityPopular.vue'
import HomeType from './components/home/HomeType'
import Loading from './components/Loading'
import Tips from './components/tips/Tips'
import TipCard from './components/tips/TipCard'
import Social from './components/auth/Social'
import LoginDialog from './components/auth/LoginDialog'
import CommentDialog from './components/comment/CommentDialog'
import ReadMore from './components/others/ReadMore'
import BookingDialog from './components/activity/BookingDialog'
import ConfirmDialog from './components/activity/ConfirmDialog'
import Packages from './components/activity/Packages'
import IntegerPlusminus from './components/IntegerPlusminus'
import HomeEvent from './components/home/event/HomeEvent'
import HomeEventCard from './components/home/event/HomeEventCard'
import HomeSpecialOffer from './components/home/specialoffer/HomeSpecialOffer'
import HomeSpecialOfferCard from './components/home/specialoffer/HomeSpecialOfferCard'
import GalleryHome from './components/home/gallery/GalleryHome'
import GalleryPhotos from './components/home/gallery/GalleryPhotos'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMoment from 'vue-moment'

import TransportFeature from './components/home/TransportFeature'
import HomeAbout from './components/home/HomeAbout'

import moment from 'moment'
import ActivityWaterSport from './components/home/activity/ActivityWaterSport.vue'
import ActivityTransport from './components/home/activity/ActivityTransport.vue'
import ActivityBaliTour from './components/home/activity/ActivityBaliTour.vue'
import ActivityTourAct from './components/home/activity/ActivityTourAct.vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAPbrAMB643vjjYI2nojBgHcuDmX-5RM6M'
  }
})

Vue.use(VueMoment)

Vue.use(VueScrollTo)

Vue.component('nav-header', NavHeader)
Vue.component('activity-card', ActivityCard)
Vue.component('activity-card-detail', ActivityCardDetail)
Vue.component('activity-detail', ActivityDetail)
Vue.component('price-detail', PriceDetail)
Vue.component('destination-card', DestinationCard)
Vue.component('transport-card', TransportCard)
Vue.component('transport-detail', TransportDetail)
Vue.component('faq', Faq)
Vue.component('comment-item', CommentItem)
Vue.component('comment-header', CommentHeader)
Vue.component('comment-overview', CommentOverview)
Vue.component('main-card', MainCard)
Vue.component('home-feature', HomeFeature)
Vue.component('home-driver', HomeDrivers)
Vue.component('home-populars', HomePopulars)
Vue.component('home-water-sport', HomeWaterSport)
Vue.component('home-recommendations', HomeRecommendations)
Vue.component('home-recent-global', HomeRecentView )
Vue.component('home-reviews', HomeReviews)
Vue.component('home-review-card', HomeReviewCard)
Vue.component('activity-review-card', ActivityReviewCard)
Vue.component('home-tour', HomeTour)
Vue.component('home-activitiy-card', HomeActivitiyCard)
Vue.component('home-destinations', HomeDestinations)
Vue.component('home-banner', HomeBanner)
Vue.component('home-destination-card', HomeDestinationCard)
Vue.component('home-tips', HomeTips)
Vue.component('home-tips-card', HomeTipsCard)
Vue.component('home-categories', HomeCategories)
Vue.component('home-type', HomeType)
Vue.component('activity-recommendation', ActivityRecommendation)
Vue.component('activity-popular', activityPopular)
Vue.component('activity-transport', ActivityTransport)
Vue.component('activity-balitour', ActivityBaliTour)
Vue.component('activity-baliact', ActivityTourAct)
Vue.component('activity-water-sport', ActivityWaterSport)
Vue.component('loading', Loading)
Vue.component('tips', Tips)
Vue.component('tips-card', TipCard)
Vue.component('social-button', Social)
Vue.component('login-dialog', LoginDialog)
Vue.component('comment-dialog', CommentDialog)
Vue.component('read-more', ReadMore)
Vue.component('dialog-booking', BookingDialog)
Vue.component('dialog-confirm', ConfirmDialog)
Vue.component('packs', Packages)
Vue.component('integer-plusminus', IntegerPlusminus)
Vue.component('home-event', HomeEvent)
Vue.component('home-event-card', HomeEventCard)
Vue.component('home-specialoffer', HomeSpecialOffer)
Vue.component('home-specialoffer-card', HomeSpecialOfferCard)
Vue.component('gallery-home', GalleryHome)
Vue.component('video-gallery', VideoHome)
Vue.component('video-bali', HomeVideoBali)
Vue.component('gallery-photos', GalleryPhotos)
Vue.component('all-video-visitor', AllVideoVisitor)
Vue.component('all-video-balitrip', AllVideoBalitrip)
Vue.component('transport-feature', TransportFeature)
Vue.component('home-about', HomeAbout)
Vue.component('all-reviews', AllReview)
Vue.component('all-activity-reviews', ActivityReview)
Vue.component('activity-detail-review', AllReviewActivity)
Vue.config.productionTip = false

import axios from 'axios'
import HomeRecentView from './components/home/activity/HomeRecentView.vue'
import HomeBanner from './components/home/activity/HomeBanner.vue'
import VideoHome from './components/home/gallery/videoHome.vue'
import HomeVideoBali from './components/home/activity/HomeVideoBali.vue'
import AllVideoVisitor from './components/home/gallery/AllVideoVisitor.vue'
import AllVideoBalitrip from './components/home/gallery/AllVideoBalitrip.vue'
import AllReview from './components/home/review/AllReview.vue'
import ActivityReview from './components/activity/ActivityReview.vue'
import AllReviewActivity from './components/activity/AllReviewActivity.vue'
import ActivityReviewCard from './components/home/activity/ActivityReviewCard.vue'

const currentLocale = store.getters.getLanguage || 'en'
axios.defaults.headers.common['locale'] = currentLocale


Vue.use(VueAgile)

//format money
Vue.filter('fm', function (value) {
  if (value == 0) return 0;
  if (!value) return ''
  var reverse = value.toString().split('').reverse().join('');
  var ribuan = reverse.match(/\d{1,3}/g);
  ribuan = ribuan.join('.').split('').reverse().join('');
  return ribuan
});

Vue.filter('showDate', function(value){
  return moment(value).format('D MMMM Y')
})

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
