<template>
  <div>
    <div v-for="(pack, index) in packages" :key="index" >
      <div class="header-custom">
        <v-flex xs12>
          <span>{{ pack.name }}</span>
        </v-flex>
      </div>
      <div class="price">
        <v-layout
          row
          wrap
          py-2
          v-show="active !== index"
          style="
            font-size: 14px !important;
            margin: 0.5px 12px;
            border-bottom: 1px dashed #303135;
          "
        >
          <v-flex xs8>
            <span>{{ pack.products[0].name }}</span
            ><br />
          </v-flex>
          <v-flex xs4 class="text-xs-right">
            <span>
              {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
              {{
                $root.$i18n.locale == "id"
                  ? pack.products[0].price_idr
                  : pack.products[0].price_usd | fm
              }}</span
            >
          </v-flex>
        </v-layout>
        <div v-show="active === index">
          <div
            class="product_item"
            row
            wrap
            v-for="(product, index) in pack.products"
            :key="index"
          >
            <v-layout>
              <v-flex xs8 pl-4>
                <span>{{ product.name }}</span
                ><br />
              </v-flex>
              <v-flex xs4 pr-4 class="text-xs-right">
                <span>
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                  {{
                    $root.$i18n.locale == "id"
                      ? product.price_idr
                      : product.price_usd | fm
                  }}</span
                >
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap pa-3>
            <v-flex xs12>
              <span
                style="color: #000"
                class="detai-pack"
                v-html="pack.detail"
              ></span>
            </v-flex>
          </v-layout>
        </div>
        <v-layout row wrap v-show="active !== index" pa-0>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-content: space-between;
              width: 100%;
              padding: 12px 17px;
              gap: 18px;
              align-items: center;
            "
          >
            <button
              class="deep-orange white--text text-capitalize"
              style="width: 100%"
              @click="$emit('booking-now')"
            >
              {{ $t("book-now") }}
            </button>
            <div class="deep-black" @click="setActive(index)">
              {{ $t("details") }}
            </div>
          </div>
        </v-layout>
        <v-layout row wrap v-show="active === index" pa-0>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-content: space-between;
              width: 100%;
              padding: 24px 17px;
              gap: 18px;
              align-items: center;
            "
          >
            <button
              class="deep-orange white--text text-capitalize"
              style="width: 100%"
              @click="$emit('booking-now')"
            >
              {{ $t("book-now") }}
            </button>
            <div class="deep-black" @click="setActive(index)">
              {{ $t("details") }}
            </div>
          </div>
        </v-layout>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["packages"],
  data() {
    return {
      active: null,
    };
  },
  methods: {
    setActive(index) {
      this.active === index ? (this.active = null) : (this.active = index);
    },
  },
};
</script>
<style scoped>
.header-custom {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #e1e1e1;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  border-color: #303135;
}

.grey--text {
  text-align: right;
  padding: 12px 0 0 0;
}
.price {
  border-color: #303135;
  border-width: 2px;
  color: #000 !important;
  border-style: solid;
  min-width: 100% !important;
  margin-bottom: 8px;
  background-color: white;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.pack {
  font-size: 16px;
  font-weight: bold;
}

.orange--box {
  border-width: 1px;
  padding: 5px 12px;
  border-style: solid;
  border-color: #ff6100;
  border-radius: 5px;
  text-align: right;
}

.product_item {
  padding: 12px 0;
  margin: 0 12px;
  font-size: 14px;
  color: #000 !important;
  border-bottom: 1px dashed #303135;
}

.deep-orange {
  padding: 12px 0;
  border-radius: 5px;
  font-weight: bold !important;
}

.deep-black {
  padding: 12px 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border: 1px solid #303135;
}
</style>
