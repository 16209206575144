<template>
  <div>
    <v-card
      elevation="4"
      class="rounded black--text card-container"
      :to="{
        name: 'activity',
        params: { slug: post.slug, type: post.type.slug },
      }"
    >
      <!-- Thumbnail Slider -->
      <div class="image-container">
        <vue-slick-carousel :settings="sliderSettings">
          <div v-for="(image, index) in post.images" :key="index">
            <v-img
              :src="image.thumbnail"
              :alt="'Image ' + index"
              height="200px"
              width="100%"
              class="img-card"
              style="border-bottom-left-radius: 30px !important"
            ></v-img>
          </div>
        </vue-slick-carousel>

        <!-- Location (top-left) -->
        <div class="overlay-top-left">
          <small class="mr-2">
            <v-icon size="14" style="color: #fff">mdi-map-marker</v-icon>
            {{ post.location }}
          </small>
        </div>

        <!-- Rating (bottom-left) -->
        <div class="overlay-bottom-left">
          <b>
            {{ post.rating }}
          </b>
          <v-icon size="14" style="color: orange">mdi-star</v-icon>
          (
          {{ post.comments_count }}
          )
        </div>

        <!-- Price (bottom-right) -->
        <div class="overlay-bottom-right">
          start from
          <div>
            <small class="price-orange">
              {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
            </small>
            <span class="price-orange">
              {{
                $root.$i18n.locale == "id"
                  ? post.price_idr
                  : post.price_usd | fm
              }}
            </span>
          </div>
        </div>
      </div>

      <v-card-text>
        <b class="title black--text" style="font-weight: 700;font-size: 16px !important;">{{ post.name }}</b>
        <br />
        <div class="short_des">{{ post.short_des }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: ["post"],
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      sliderSettings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
};
</script>

<style scoped>
.card-container {
  position: relative;
}

.v-responsive {
  border-radius: 9px; 
  border-bottom-left-radius: 30px;
}

.image-container {
  position: relative;
}

.overlay-top-left {
  position: absolute;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.overlay-bottom-left {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.overlay-bottom-right {
  position: absolute;
  background-color: #000;
  padding: 8px 12px;
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: column;
}

.overlay-top-left {
  top: 8px;
  left: 8px;
}

.overlay-bottom-left {
  bottom: 22px;
  left: 8px;
}

.overlay-bottom-right {
  bottom: 14px;
  right: 0;
}

.rating {
  background-color: black;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  width: 60px;
  font-size: 12px;
  max-width: 60px;
  margin-right: 4px;
}

.price-orange {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.rounded {
  border-radius: 14px !important;
}

.short_des {
  line-height: normal;
  padding: 8px 0;
  border-radius: 8px;
}

/* create overlay image gradient #000 to transparent from top to bottom */
.img-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.52) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-bottom-left-radius: 30px;
}
</style>
