<template>
  <div>
    <div v-if="!loading">
      <div
        style="
          padding: 4px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="margin-left: 5px; font-size: 16px; font-weight: 700">
          Popular Activities
        </div>
        <a
          href="activity"
          style="
            color: #000;
            text-decoration: none;
            font-weight: 500;
            font-size: 14px;
          "
        >
          {{ $t("view_all") }}
        </a>
      </div>
      <div v-if="items && items.length" style="margin-top: 20px">
        <v-container fluid grid-list-md pa-2>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex
                v-for="(post, i) in items.slice(0, 3)"
                :key="i"
                xs12
                sm6
                xl4
              >
                <div
                  class="card"
                
                  @click="goToActivity(post.slug, post.type.slug)"
                >
                  <!-- Thumbnail with absolute love icon -->
                  <div style="position: relative">
                    <img
                      :src="post.images[0]?.thumbnail"
                      alt="Thumbnail"
                      style="
                        height: 236px;
                        object-fit: cover;
                        border-radius: 8px;
                      "
                    />
                    <img
                      src="@/assets/images/icons/love.svg"
                      alt="Love Icon"
                      style="
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 30px;
                        height: auto;
                      "
                    />
                  </div>
                  <!-- Title -->
                  <div
                    style="
                      text-align: left;
                      font-weight: 600;
                      margin-top: 10px;
                      font-size: 12px !important;
                    "
                  >
                    {{ truncateText(post.name, 45) }}
                  </div>
                  <!-- Price -->
                  <div
                    style="
                      text-align: left;
                      font-size: 12px;
                      color: gray;
                      margin-top: 5px;
                    "
                  >
                    starting from
                    <small>
                      {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                    </small>
                    <span>
                      {{
                        $root.$i18n.locale == "id"
                          ? post.price_idr
                          : post.price_usd
                      }}
                    </span>
                  </div>
                </div>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    goToActivity(slug, type) {
      this.$router.push({ name: "activity", params: { slug, type } });
    },
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/type/12`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #000;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: gray;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
