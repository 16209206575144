<template>
  <div>
    <!-- Image Grid -->
    <div v-if="posts && posts.length" v-show="!loading">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(image, i) of posts" :key="i" xs6 sm3>
            <v-card class="black--text" @click="openModal(i)">
              <v-img
                :src="image.thumbnail"
                :alt="image.thumbnail"
                height="150px"
                width="100%"
              >
              </v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <!-- Modal Dialog with Carousel -->
    <v-dialog v-model="isModalOpen" fullscreen overlay-color="black">
      <v-card class="d-flex flex-column align-center justify-center" style="background-color:black !important;">
        <!-- Close Button -->
        <v-btn
          class="close-button"
          icon
          color="white"
          @click="isModalOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-carousel
          v-model="currentIndex"
          height="100%"
          hide-delimiters
          show-arrows
          class="fill-height"
        >
          <v-carousel-item
            v-for="(image, i) in posts"
            :key="i"
            :src="image.thumbnail"
          >
            <v-img :src="image.thumbnail" class="fill-height"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <!-- Loading Spinner -->
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
const api = "https://balitripcenter.com/api/gallery";

export default {
  data() {
    return {
      loading: false,
      posts: [],
      errors: [],
      isModalOpen: false,
      currentIndex: 0,
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          this.posts = response.data.items.images;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    openModal(index) {
      this.currentIndex = index;
      this.isModalOpen = true;
    },
  },
};
</script>

<style scoped>
/* Custom styles for fullscreen carousel */
.v-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Ensure the background is black */
}

.v-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

/* Close Button Styling */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}
</style>
