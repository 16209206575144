<template>
  <div>
    <div class="gallery" v-if="photos && photos.length" v-show="!loading">
      <v-container pa-2 fluid>
        <v-row align="center" justify="start" class="mb-2 pa-2">
          <img
            src="@/assets/images/video.svg"
            alt="arrow-right"
            style="width: 31px; height: 31px; margin-right: 8px"
          />
          <div class="headline" style="font-weight: 700 !important">
            {{ $t("video-balitrip") }}
          </div>
        </v-row>
      </v-container>

      <!-- VueSlickCarousel -->
      <VueSlickCarousel v-bind="settings" class="gallery-carousel">
        <div v-for="(photo, index) in photos" :key="index" class="gallery-item">
          <img
            :src="photo.thumbnail"
            :alt="photo.thumbnail"
            class="gallery-image"
          />
          <!-- Play Video Icon -->
          <img
            src="@/assets/images/play-video.svg"
            alt="Play Video"
            class="play-video-icon"
            @click="openModal(index)"
          />
        </div>
      </VueSlickCarousel>

      <router-link :to="{ name: 'videoBalitrip' }" class="mt-2">
        <button type="button" class="custom-btn2">
          {{ $t("watch-all-video") }}
        </button>
      </router-link>
    </div>

    <div
      v-if="isModalOpen"
      class="video-modal"
      @touchstart="startTouch"
      @touchmove="onSwipe"
      @touchend="endTouch"
    >
      <!-- button close absolute top left-->
      <v-btn
        icon
        class="close-btn"
        @click="isModalOpen = false"
        style="
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 999999;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 4px;
        "
      >
        <v-icon style="font-size: 32px">mdi-close</v-icon>
      </v-btn>
      <video
        controls
        autoplay
        class="video-player"
        ref="videoPlayer"
        :src="`${getVideoPath(photos[activeIndex])}`"
        controlsList="nodownload"
      ></video>
    </div>

    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const api = "https://balitripcenter.com/api/video-balitrip";

export default {
  name: "VideoGallery",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      photos: [],
      activeIndex: 0,
      isModalOpen: false,
      baseUrl: "https://balitripcenter.com/storage",
      settings: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  computed: {},
  methods: {
    getThumbnail(photo) {
      const thumbnailKey = `thumbnail`;
      return photo[thumbnailKey] || photo.thumbnail;
    },
    getVideoPath(photo) {
      const videoPathKey = `video_path`;
      return photo[videoPathKey] || photo.video_path;
    },
    async getInitialPosts() {
      this.loading = true;
      try {
        const response = await fetch(api);
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let result = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          result += decoder.decode(value, { stream: true });
        }

        const parsedData = JSON.parse(result);
        this.photos = parsedData.items;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    openModal(index) {
      this.activeIndex = index;
      this.isModalOpen = true;
    },
    nextVideo() {
      this.activeIndex = (this.activeIndex + 1) % this.photos.length;
    },
    prevVideo() {
      this.activeIndex =
        (this.activeIndex - 1 + this.photos.length) % this.photos.length;
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onSwipe(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    endTouch() {
      if (this.touchStartY > this.touchEndY + 50) {
        this.nextVideo();
      } else if (this.touchStartY < this.touchEndY - 50) {
        this.prevVideo();
      }
    },
  },
  watch: {
    isModalOpen(newValue) {
      if (!newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.pause();
        }
      }
    },
  },
  created() {
    this.getInitialPosts();
  },
};
</script>

<style scoped>
.headline {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.lead {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-btn {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-btn2 {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  background-color: #ff6100;
  color: white;
  max-width: 274px;
  width: 274px;
  display: block;
  margin: 20px auto 0 auto;
  font-weight: 700;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.gallery {
  padding: 30px 8px;
  border-radius: 8px;
  position: relative;
  width: 100vw;
  left: -12px;
  background-color: #e1e1e1;
  background-size: cover;
}

.gallery-carousel {
  margin-top: 14px;
}

.gallery-item-card {
  margin: 20px 15px; /* Space between items */
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
}

/* Ensure gallery items have correct positioning */
.gallery-item {
  position: relative;
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-image {
  border-radius: 8px;
  width: calc(100% - 10px);
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.slick-active .gallery-item-card {
  width: 26vw;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.thumbnail-image {
  border-radius: 25px;
  width: 108px;
  height: 102px;
}

.gallery-item-card img {
  width: calc(100% - 10px);
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-slide.slick-center .gallery-item-card img {
  height: 228px;
  width: calc(34vw - 10px);
  transition: all 0.3s ease;
}

.gallery .slick-slider .slick-list {
  /* overflow-y:visible !important; */
  overflow: visible !important;
}

/* Custom slick carousel modifications */
.slick-track {
  display: flex !important;
  gap: 20px !important; /* Add 20px gap between slides */
  align-items: center !important; /* Align slides vertically to center */
  transition: all 0.3s ease;
}

.slick-slide {
  display: flex !important;
  margin: 0 5px;
  justify-content: center !important;
  transition: all 0.3s ease;
  align-items: center !important;
  height: 100%; /* Ensure full height for vertical alignment */
}
</style>
