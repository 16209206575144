<template>
  <div style="position: relative">
    <div class="header-contanier2" v-show="isVisible">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 14px 8px;
          background-color: #f6f6f6;
        "
      >
        <div style="display: flex; gap: 5px; align-items: center">
          <router-link to="/" class="back-btn">
            <v-btn
              icon
              class="back-btn"
              style="
                background-color: white;
                padding: 4px;
                color: #000 !important;
                font-size: 16px !important;
              "
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </router-link>
          <div class="search-container">
            {{
              post.name.length > 30
                ? post.name.substring(0, 30) + "..."
                : post.name
            }}
          </div>
        </div>
        <v-btn
          icon
          @click="copyToClipboard"
          style="background-color: white; padding: 4px; color: #000 !important"
        >
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </div>
      <div
        class="pa-0 scrolling-wrapper"
        pa-0
        style="margin-bottom: 0 !important"
      >
        <div v-for="item in categories" :key="item.name">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <div>{{ item.name }}</div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :loading="loading"></loading>
    <div v-if="post" v-show="!loading">
      <div style="position: relative">
        <v-btn
          color="white"
          class="black--text"
          style="
            border-radius: 9px;
            position: absolute;
            top: 32px;
            left: 16px;
            z-index: 1;
            min-width: 48px !important;
            padding: 4px !important;
          "
          @click="$router.push({ name: 'activity' })"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <VueSlickCarousel
          ref="c1"
          :asNavFor="$refs.c2"
          :focusOnSelect="true"
          :arrows="false"
          :dots="false"
        >
          <div v-for="(photo, index) in post.images" :key="index">
            <div
              class="gallery-item-card-large"
              style="
                height: 251px !important;
                min-height: 251px !important;
                max-height: 251px !important;
              "
            >
              <img
                :src="photo.thumbnail"
                :alt="photo.thumbnail"
                class="gallery-image"
                style="
                  height: 100% !important;
                  min-height: 100% !important;
                  max-height: 100% !important;
                "
              />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <VueSlickCarousel
        ref="c2"
        :asNavFor="$refs.c1"
        :slidesToShow="2.5"
        :focusOnSelect="true"
        :arrows="false"
        :dots="false"
        class="gallery-carousel"
      >
        <div v-for="(photo, index) in post.images" :key="index">
          <div class="gallery-item-card">
            <img
              :src="photo.thumbnail"
              :alt="photo.thumbnail"
              class="gallery-image"
            />
          </div>
        </div>
      </VueSlickCarousel>
      <v-container fluid grid-list-md pa-0>
        <v-layout row wrap>
          <v-flex xs12 md7>
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card color="grey darken-4">
                <v-flex xs12>
                  <v-btn class="ma-4" icon dark @click.native="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 text-sm-center class="center">
                  <v-carousel class="carousel-img" hide-delimiters>
                    <v-carousel-item v-for="(image, i) in post.images" :key="i">
                      <img
                        class="img"
                        style="margin-top: 120px"
                        :src="image.original"
                        alt=""
                      />
                    </v-carousel-item>
                  </v-carousel>
                </v-flex>
              </v-card>
            </v-dialog>
            <v-layout row wrap px-3 id="detail">
              <v-flex xs12 my-2>
                <div class="activity-title">{{ post.name }}</div>
              </v-flex>

              <div
                style="
                  display: flex;
                  gap: 8px;
                  margin-top: 5px;
                  margin-bottom: 8px;
                "
              >
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.location }}
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.required }} Person
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.duration }} Hours
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.cancelation }} hrs Cancelation
                </div>
              </div>
            </v-layout>

            <v-container class="pa-3">
              <v-row align="center" no-gutters>
                <!-- Rating Box -->
                <v-col cols="auto">
                  <div class="rating-box">
                    <span class="rating-value">
                      {{ post.rating }}
                    </span>
                  </div>
                </v-col>

                <!-- Stars -->
                <v-col cols="auto" class="ml-2">
                  <v-icon v-for="n in 5" :key="n" color="orange" size="24"
                    >mdi-star</v-icon
                  >
                </v-col>

                <!-- Review Count -->
                <v-col cols="auto" class="ml-2">
                  <span class="review-text"
                    >( {{ post.comments_count }} Review )</span
                  >
                </v-col>

                <!-- Booking Count -->
                <v-col cols="auto" class="ml-2">
                  <span class="booking-text">( 201 booked )</span>
                </v-col>
              </v-row>

              <div
                px-3
                style="
                  margin-top: 14px;
                  padding: 10px 0 !important;
                  font-size: 14px !important;
                "
              >
                {{ post.short_des }}
              </div>
            </v-container>

            <!-- <v-layout row wrap px-3>
              <v-flex xs6>
                <div>
                  <div class="float-bottom">
                    <span
                      class="text"
                      style="font-size: 16px !important; color: #000 !important"
                      >{{ $t("product-id-post-code", [post.code]) }}</span
                    >
                  </div>
                </div>
              </v-flex>
              <v-flex xs6>
                <span class="float-right" style="font-size: 16px !important">
                  {{ $t("overview") }}
                </span>
              </v-flex>

              <v-flex xs12 class="threebox">
                <v-card
                  outlined
                  style="
                    border-radius: 20px !important;
                    border-color: #212121 !important;
                    overflow: hidden;
                  "
                >
                  <v-layout row wrap align-center>
                    <v-flex xs4>
                      <div
                        class="d-flex align-center justify-center font-weight-bold"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <v-icon color="yellow accent-4">mdi-star</v-icon>
                        <span
                          class="headline"
                          style="
                            font-size: 24px;
                            margin-left: 4px;
                            font-weight: bold;
                          "
                        >
                          {{ post.rating }}
                        </span>
                      </div>
                      <div class="text-center">
                        <span
                          class="subheading black--text"
                          style="font-size: 12px; margin-top: 4px"
                        >
                          [{{ post.comments_count }} {{ $t("reviews") }}]
                        </span>
                      </div>
                    </v-flex>

                    <v-divider vertical class="custom-divider"></v-divider>

                    <v-flex
                      xs4
                      style="
                        padding: 24px 8px !important;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <img
                        src="@/assets/images/persons-group.svg"
                        alt="person group"
                        style="width: 24px; height: 24px; margin-bottom: 4px"
                      />
                      <div class="text-center">
                        <span style="font-size: 12px"
                          >[Min {{ post.avg_person }} Person]</span
                        >
                      </div>
                    </v-flex>

                    <v-divider vertical class="custom-divider"></v-divider>

                    <v-flex
                      xs4
                      style="
                        padding: 24px 8px !important;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <img
                        src="@/assets/images/timer-group.svg"
                        alt="timer group"
                        style="width: 24px; height: 24px; margin-bottom: 4px"
                      />
                      <div class="text-center">
                        <span
                          class="subheading black--text"
                          style="font-size: 12px; margin-top: 4px"
                        >
                          [{{ $t("duration") }} {{ post.duration }} hrs]
                        </span>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <div
                px-3
                style="
                  padding: 10px 12px !important;
                  font-size: 14px !important;
                "
              >
                {{ post.short_des }}
              </div>
            </v-layout> 
          -->
            <v-flex xs12 px-3 id="highlight">
              <v-expansion-panels>
                <v-expansion-panel popout>
                  <v-expansion-panel-header>
                    <b>{{ $t("detail-information") }}</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <activity-detail :post="post" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>

            <v-layout row wrap>
              <v-flex xs12 d-inline-flex>
                <div id="description">
                  <v-card
                    elevation="1"
                    style="margin-bottom: 12px; margin-top: 18px"
                  >
                    <div style="margin: 0px 16px">
                      <b class="before_text">{{ $t("description") }}</b>
                    </div>
                    <v-card-text style="font-size: 14px !important">
                      <div v-html="post.activity_des"></div>
                    </v-card-text>
                  </v-card>
                  <v-card elevation="1" mb-2 style="margin-bottom: 12px">
                    <v-card-text>
                      <div>
                        <b class="before_text">{{ $t("rate-includes") }}</b>
                        <div class="check--green" v-html="post.included"></div>
                        <br />
                      </div>
                    </v-card-text>
                  </v-card>
                  <div
                    style="margin-bottom: 12px; padding: 12px"
                    v-if="post.packages.length"
                    id="pricing"
                  >
                    <b class="before_text">{{ $t("pricing-detail") }}</b>
                    <price-detail
                      :packages="post.packages"
                      @booking-now="bookingDialog = true"
                    ></price-detail>
                  </div>
                  <v-flex xs12 px-3 id="confirmation">
                    <v-expansion-panels style="margin-bottom: 20px">
                      <v-expansion-panel popout v-if="post.confirmation_detail">
                        <v-expansion-panel-header
                          ><b class="before_text">{{
                            $t("confirmation-detail")
                          }}</b></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-flex v-html="post.confirmation_detail"></v-flex
                          ><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel popout text v-if="post.whattobring">
                        <v-expansion-panel-header
                          ><b class="before_text">{{
                            $t("what-to-bring")
                          }}</b></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-flex v-html="post.whattobring"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout text v-if="post.pickup_time">
                        <v-expansion-panel-header
                          ><b class="before_text">{{
                            $t("pickup-time")
                          }}</b></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-flex v-html="post.pickup_time"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout text v-if="post.how_to_use">
                        <v-expansion-panel-header
                          ><b class="before_text">{{
                            $t("how-to-use")
                          }}</b></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-flex v-html="post.how_to_use"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout v-if="post.payment">
                        <v-expansion-panel-header
                          ><b class="before_text"
                            >{{ $t("payment") }}
                            {{ $t("and-cancelation-policy") }}</b
                          ></v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-flex v-html="post.payment"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-container fluid>
          <all-activity-reviews
            :posts="comments"
            :activity="post"
            :slug="$route.params.slug"
            :type="$route.params.type"
          ></all-activity-reviews>
        </v-container>
      </v-container>
    </div>

    <v-flex class="sticky" pa-0 xs12 background-color="white">
      <v-divider></v-divider>
      <v-container grid-list-xs pa-0>
        <v-layout row wrap pl-4 pr-2 align-center>
          <v-flex xs7>
            <small>{{ $t("starting-from") }}</small
            ><br />
            <span class="green-text">
              {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
              {{
                $root.$i18n.locale == "id"
                  ? post.price_idr
                  : post.price_usd | fm
              }}
            </span>
            <small>{{ $t("price-post-price_type", [post.price_type]) }}</small>
          </v-flex>
          <v-flex xs4 class="text--right">
            <dialog-booking
              :dialog="bookingDialog"
              :bookingdata="bookingdata"
              :post="post"
              :type="type"
              @bookingData="bookingData"
              @bookingCancel="bookingDialog = false"
            ></dialog-booking>
            <dialog-confirm
              :dialog="confirmDialog"
              :bookingdata="bookingdata"
              :post="post"
              :type="type"
              @confirmSuccess="confirmSuccess"
              @confirmCancel="confirmDialog = false"
            ></dialog-confirm>
            <v-btn
              depressed
              rounded
              color="blue-grey darken-3 white--text text-capitalize"
              block
              @click="bookingDialog = true"
            >
              <span class="subheading">{{ $t("book-now") }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-dialog v-model="bookingSuccessDialog" persistent max-width="290">
      <v-card>
        <img class="success--image" src="@/assets/images/done.png" alt="" />
        <v-card-text>
          <div class="text--center">
            <span class="headline deep-orange--text text-darken-1">{{
              $t("thank-you")
            }}</span
            ><br />
            <span class="subheading"
              >{{
                $t(
                  "please-wait-for-our-confirmation-and-check-your-email-inbox"
                )
              }}.</span
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="bookingSuccessDialog = false">{{
            $t("ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card elevation="0" style="margin-bottom: 8px">
        <div class="additonal" v-if="post.note">
          <v-flex xs12>
            <v-icon color="orange">mdi-information</v-icon>
            <b class="orange--text text-darken-1">{{
              $t("additional-information")
            }}</b>
          </v-flex>
          <v-flex v-html="post.note"></v-flex><br />
        </div>
      </v-card>
    </v-container>

    <v-container>
      <button
        @click="$router.push({ name: 'helpandfaq' })"
        style="
          display: inline-flex;
          align-items: center;
          width: 100%;
          border: 1px solid #757575;
          background-color: transparent;
          color: #000;
          justify-content: center;
          align-items: center;
          margin: 20px 0 30px 0;
          font-size: 14px;
          font-weight: 700;
          padding: 8px 8px;
          border-radius: 9px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        "
      >
        <img
          src="@/assets/images/faq-new.svg"
          alt="FAQ"
          style="width: 33px; height: 33px; margin-right: 4px"
        />
        {{ $t("faqs") }}
      </button>
    </v-container>

    <v-container fluid>
      <v-flex xs12 px-3>
        <v-flex mt-2 mb-2>
          <span
            style="
              font-size: 16px !important;
              font-weight: 700;
              margin-bottom: 16px !important;
            "
            >{{ $t("discover-more-bali-activities") }}</span
          >
        </v-flex>
        <v-flex v-for="(post, i) of others" :key="i" mt-2>
          <activity-card-detail :post="post"></activity-card-detail>
        </v-flex>
      </v-flex>
    </v-container>

    <div class="box">
      <v-layout>
        <v-flex xs12 items-center text-center>
          <img
            src="@/assets/images/icons/livechat-new.svg"
            height="auto"
            alt="$t('contact-us')"
            style="margin: auto; width: 84px"
          /><br />
          <h4
            class="text-h4"
            style="
              font-size: 14px !important;
              text-align: center;
              font-weight: 600;
            "
          >
            Live Report
          </h4>
          <span class="caption">{{
            $t("any_question_about_balitripcenter")
          }}</span
          ><br />
          <a class="email" href="https://wa.me/6285857082012" target="_blank">
            <span class="caption">+62 85857082012</span> </a
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      active: 1,
      commentsloading: false,
      post: null,
      isVisible: false,
      type: null,
      terjemah: [],
      others: [],
      errors: [],
      categories: [],
      categoriesEn: [
        { id: 1, name: "Detail Information", linKHref: "#detail" },
        { id: 2, name: "Highlight", linKHref: "#highlight" },
        { id: 3, name: "Description", linKHref: "#description" },
        { id: 4, name: "Pricing Detail", linKHref: "#pricing" },
        { id: 5, name: "Confirmation Detail", linKHref: "#confirmation" },
        { id: 6, name: "What to Bring", linKHref: "#confirmation" },
        { id: 7, name: "Pickup Time", linKHref: "#confirmation" },
        { id: 8, name: "How to Use", linKHref: "#confirmation" },
        {
          id: 9,
          name: "Payment and Cancelation Policy",
          linKHref: "#confirmation",
        },
      ],
      categoriesId: [
        { id: 1, name: "Informasi Detail", linKHref: "#detail" },
        { id: 2, name: "Highlight", linKHref: "#highlight" },
        { id: 3, name: "Deskripsi", linKHref: "#description" },
        { id: 4, name: "Detail Harga", linKHref: "#pricing" },
        { id: 5, name: "Detail Konfirmasi", linKHref: "#confirmation" },
        { id: 6, name: "Apa yang Harus Dibawa", linKHref: "#confirmation" },
        { id: 7, name: "Waktu Penjemputan", linKHref: "#confirmation" },
        { id: 8, name: "Cara Penggunaan", linKHref: "#confirmation" },
        {
          id: 9,
          name: "Kebijakan Pembayaran dan Pembatalan",
          linKHref: "#confirmation",
        },
      ],
      commentsUrl: null,
      comments: [],
      totalComments: null,
      commentDialog: false,
      loginDialog: false,
      overview: null,
      dialog: false,
      bookingSuccessDialog: false,
      bookingdata: null,
      bookingDialog: false,
      confirmDialog: false,
      settings: {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2.5,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
    };
  },
  mounted() {
    this.fetchData();
    // this.getComments()
    if (localStorage.getItem("preferredLanguage") === "en") {
      this.categories = this.categoriesEn;
    } else {
      this.categories = this.categoriesId;
    }
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    selected(id) {
      this.active = id;
      const category = this.categories.find((cat) => cat.id === id);
      if (category) {
        const element = document.querySelector(category.linKHref);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(window.location.href);
      this.$toast.success(this.$t("link-copied"));
    },
    fetchData() {
      this.loading = true;
      axios
        .get(
          `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}?lang=${this.$root.$i18n.locale}/`
        )
        .then((response) => {
          this.loading = false;
          this.type = response.data.items.type;
          this.post = response.data.items;
          this.terjemah = response.data.terjemah;
          this.others = response.data.others;
        })
        .then((response) => {
          this.commentsUrl = `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
          this.getComments();
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getComments() {
      this.commentsloading = true;
      axios
        .get(this.commentsUrl)
        .then((response) => {
          this.commentsloading = false;
          this.comments = this.comments.concat(response.data.items);
          this.overview = response.data.overview;
          this.commentsUrl = response.data.items.next_page_url;
          this.totalComments = response.data.items.total;
        })
        .catch((e) => {
          this.commentsloading = false;
          this.errors.push(e);
        });
    },
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
    loginSuccess() {
      this.loginDialog = false;
      this.commentDialog = true;
    },
    commentSuccess() {
      this.commentDialog = false;
      this.getComments();
    },
    bookingData(bookingdata) {
      this.bookingDialog = false;
      this.confirmDialog = true;
      this.bookingdata = bookingdata;
    },
    bookingCancel() {
      this.bookingDialog = false;
      this.bookingdata = null;
    },
    confirmCancel() {
      this.confirmDialog = false;
    },
    confirmSuccess(response) {
      this.confirmDialog = false;
      this.bookingSuccessDialog = true;
    },
    showHeader() {
      this.isVisible = window.scrollY > 450;
      console.log(this.isVisible);
    },
  },
  created() {
    window.addEventListener("scroll", this.showHeader);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showHeader);
  },
  computed: {
    url() {
      return `https://balitripcenter.com/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
    },
    remindComments() {
      return this.totalComments - this.comments.length;
    },
  },
};
</script>
<style scoped>
.header-contanier2 {
  position: fixed !important;
  top: 48px;
  z-index: 10;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  background-color: white;
  padding: 0px 8px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin: 0 8px;
  height: auto;
  min-height: 40px;
  border: 4px solid white;
  padding: 4px 0 !important;
  font-weight: 400;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 5px !important;
  scroll-snap-align: center;
}

.header-contanier2 .scrolling-wrapper .card.active {
  background-color: white;
  border-bottom-color: #ff6100;
  color: #ff6100;
}

.green-text {
  color: #0fac39;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
}

.threebox .custom-divider {
  height: 40px; /* Adjust the height to align with the content */
  margin: auto -1px;
  border-color: #212121 !important;
}

/* Remove inner borders while keeping the outer border */
.threebox .v-card {
  border: 1px solid #212121 !important;
}

.threebox .v-layout {
  border: none;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}
.v-breadcrumbs {
  padding: 8px !important;
  font-size: 12px;
}
.top {
  vertical-align: top;
}
.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.success--image {
  height: 100px;
  width: 100%;
  object-fit: scale-down;
  justify-content: center;
  margin-top: 12px;
}

.text--center {
  text-align: center;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

.v-center {
  vertical-align: middle;
}
.h-center {
  text-align: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.highlight {
  border-color: #ff6100;
  border-width: 1px;
  border-style: solid;
  padding: 4px;
  margin: 8px 0px;
  background-color: #fff;
  border-radius: 4px;
}
.additonal {
  border-color: #ff6100;
  border-width: 2px;
  border-style: solid;
  padding: 18px 8px;
  background-color: #fffde7;
  border-radius: 4px;
}

.additional ul {
  padding-left: 30px !important;
}

.before_text {
  background-image: url("~@/assets/images/before_title.svg");
  background-repeat: no-repeat;
  padding-left: 10px; /* width of the image plus a little extra padding */
  display: inline-flex; /* may not need this, but I've found I do */
  font-size: 16px;
  font-weight: 600;
  background-position: 0% 50%;
  margin-bottom: 8px;
}
div.check--green {
  list-style-image: url("~@/assets/images/check.svg");
}

.bread {
  color: black;
  text-decoration: none;
}

.bread:hover {
  text-decoration: none;
  color: black;
}
.product_id {
  font-weight: bold;
  height: 25px;
  font-size: 12px;
  position: relative;
}

.vertical-center {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.vertical-center img {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center .text {
  width: 100%;
  height: 20px;
  margin: 0;
  left: 25px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.activity-title {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.box {
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.gallery-item-card {
  max-height: 105px;
  margin-left: 5px;
}

.gallery-item-card-large {
  max-height: 251px;
}

.gallery-item-card-large img {
  width: 100%;
}

.gallery-item-card img {
  width: 100%;
}

.rating-box {
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
}
.review-text,
.booking-text {
  color: #757575;
  font-size: 16px;
}
</style>
