<template>
  <div class="review-container">
    <div class="review-section-title">
      <h4 class="subheader">{{ $t("What they say about") }}</h4>
      <h1 class="header">Bali Trip Center</h1>
    </div>
    <div v-if="!loading" style="padding-top: 130px">
      <div class="review-total-score d-flex">
        <div>
          <h3 style="border-left: 3px solid #000; padding-left: 10px;font-size:16px !important;">
            {{ $t("Customer reviews") }}
          </h3>
          <br />
          <h1>
            {{ stats.average_rating }}
            <small style="color: #71747d !important">/5</small>
          </h1>
        </div>
        <div class="text-right">
          <v-btn
            outlined
            elevation="0"
            color="dark"
            style="
              padding: 0 6px;
              text-transform: capitalize;
              border-radius: 9px;
            "
            @click="toggleLayout"
          >
            {{ isAllReviews ? $t("Back to slider") : $t("All reviews") }}
          </v-btn>
          <br /><br />
          <h3 style="line-height: 10px">{{ $t("Review") }}</h3>
          <small style="color: #757575"
            >{{ $t("_from") }} {{ stats.total_reviews }}
            {{ $t("Customer reviews") }}</small
          >
        </div>
      </div>
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout
            :class="{
              'row wrap': isAllReviews,
              'scrolling-wrapper': !isAllReviews,
            }"
          >
            <v-flex
              v-for="(post, i) in items"
              :key="i"
              :xs12="isAllReviews"
              :sm6="isAllReviews"
              :xl4="!isAllReviews"
              class="card"
            >
              <home-review-card :post="post" :index="i" :isAllReview="isAllReviews"></home-review-card>
            </v-flex>
          </v-layout>
        </v-container>

        <router-link :to="{ name: 'AllReviews' }" class="mt-2" v-if="isAllReviews">
          <button type="button" class="custom-btn2">
            {{ $t("watch-all-reviews") }}
          </button>
        </router-link>
      </div>

      <loading :loading="loading"></loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      items: [],
      stats: [],
      errors: [],
      isAllReviews: false, // State to manage layout toggle
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/comments`)
        .then((response) => {
          this.loading = false;
          this.stats = response.data.data;
          this.items = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    toggleLayout() {
      this.isAllReviews = !this.isAllReviews; // Toggle layout between slider and grid-column
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.custom-btn2 {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  background-color: #ff6100;
  color: white;
  max-width: 274px;
  width: 274px;
  display: block;
  margin: 20px auto 0 auto;
  font-weight: 700;
}

.header {
  font-size: 25px;
  font-weight: 700;
}

.review-container {
  position: relative;
}

.review-section-title {
  padding: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #e5f3fe;
  position: absolute;
  top: -10px;
  left: -14px;
  width: calc(100% + 28px);
  background: url("~@/assets/images/banner-review.png") no-repeat bottom center;
  background-size: cover;
  margin-bottom: 30px;
}

.review-total-score {
  justify-content: space-between;
  margin-bottom: 10px;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 30px 0 14px;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 30px;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.row.wrap .card {
  width: 100%; /* Full width for grid layout */
  margin-bottom: 16px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}

.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
</style>
