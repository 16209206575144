<template>
  <div style="position: relative">
    <div class="header-contanier2" v-show="isVisible">
      <div class="search-main">
        <div class="search-container">
          <input
            type="text"
            :placeholder="
              $t('find-activities-tours-4g-modem-and-private-transport')
            "
            v-model="queryInput"
          />
          <v-btn
            icon
            color="black"
            @click="toSearch"
            v-on:keyup.enter="toSearch"
          >
            <v-icon style="background-color: transparent !important"
              >mdi-magnify</v-icon
            >
          </v-btn>
        </div>
      </div>
      <div class="pa-2 scrolling-wrapper" style="margin-bottom: 0 !important">
        <div v-for="item in categories" :key="item.name">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-contanier">
      <div
        style="
          display: flex;
          align-items: center;
          gap: 5px;
          margin-bottom: 10px;
        "
      >
        <router-link to="/" class="back-btn">
          <v-btn
            icon
            class="back-btn"
            style="
              background-color: white;
              padding: 4px;
              color: #000 !important;
              font-size: 16px !important;
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </router-link>
        <h2 class="title" style="font-size: 18px !important">
          {{ dynamicName }}
        </h2>
      </div>
      <div>
        <p
          style="font-size: 14px !important; font-weight: 400 !important"
          v-if="showMore"
        >
          {{ dynamicDescription }}
        </p>
        <p
          style="font-size: 14px !important; font-weight: 400 !important"
          v-else
        >
          {{ dynamicDescription.substring(0, 143) }}
        </p>
        <v-btn
          text
          small
          color="primary"
          v-if="dynamicDescription.length > 140"
          @click="toggleShowMore"
          style="
            display: inline !important;
            padding: 0;
            min-width: 0;
            margin-bottom: 10px;
          "
        >
          {{ showMore ? $t("show-less") : $t("show-more") }}
        </v-btn>
      </div>

      <div class="search-main">
        <div class="search-container">
          <input
            type="text"
            :placeholder="
              $t('find-activities-tours-4g-modem-and-private-transport')
            "
            v-model="queryInput"
          />
          <v-btn
            icon
            color="black"
            @click="toSearch"
            v-on:keyup.enter="toSearch"
          >
            <v-icon style="background-color: transparent !important"
              >mdi-magnify</v-icon
            >
          </v-btn>
        </div>
      </div>
    </div>
    <div style="background-color: #fff" class="allcategories">
      <div class="pa-2 scrolling-wrapper">
        <div v-for="item in categories" :key="item.name">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :loading="loading"></loading>

    <v-flex sm12 pa-2>
      <div v-if="!isPostShow">
        <activity-recommendation></activity-recommendation>
        <br />
        <activity-popular></activity-popular>
        <br />
        <activity-transport></activity-transport>
        <br />
        <activity-baliact></activity-baliact>
        <br />
        <activity-balitour></activity-balitour>
        <br />
        <activity-water-sport></activity-water-sport>
        <br />
      </div>
      <div v-if="isPostShow" style="padding-bottom: 30px">
        <div v-if="posts && posts.length">
          <v-container pa-2 fluid grid-list-md>
            <v-layout row wrap>
              <v-flex pa-3 v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
                <activity-card-new :post="post"></activity-card-new>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="text-center" v-if="!loading && currentPage != null">
            <v-btn
              rounded
              class="white--text"
              color="green"
              @click="getPosts"
              >{{ $t("load-more") }}</v-btn
            >
          </div>
        </div>
        <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
          <span>{{ $t("this-category-doesnt-have-activity-yet") }}</span>
        </v-flex>
      </div>

      <home-reviews></home-reviews>
      <br />
      <gallery-home></gallery-home>
      <br />
    </v-flex>
    <home-categories :items="items"></home-categories><br />

    <div class="box">
      <v-layout>
        <v-flex xs12 items-center text-center>
          <img
            src="@/assets/images/icons/livechat-new.svg"
            height="auto"
            alt="$t('contact-us')"
            style="margin: auto; width: 84px"
          /><br />
          <h4
            class="text-h4"
            style="
              font-size: 14px !important;
              text-align: center;
              font-weight: 600;
            "
          >
            Live Report
          </h4>
          <span class="caption">{{
            $t("any_question_about_balitripcenter")
          }}</span
          ><br />
          <a class="email" href="https://wa.me/6285857082012" target="_blank">
            <span class="caption">+62 85857082012</span> </a
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import ActivityCardNew from "./ActivityCardNew.vue";
const api = "https://balitripcenter.com/api/type";

export default {
  props: ["type"],
  data() {
    return {
      loading: true,
      showMore: false,
      posts: [],
      errors: [],
      itemsCategories: [],
      currentPage: null,
      isVisible: false,
      valid: true,
      email: null,
      isPostShow: false,
      dynamicName: this.$t("discover"),
      dynamicDescription: this.$t("discover_sub"),
      categories: [
        {
          id: null,
          name: this.$t("discover"),
          title: this.$t("all-available-activities-at-our-site"),
          des: this.$t("bali-is-an-island-or-actually"),
          created_at: this.$t("2018-07-09-08-28-51"),
          updated_at: this.$t("2018-07-09-08-28-51"),
          images: [
            {
              thumbnail: require("@/assets/images/icons/discover.svg"),
              original: require("@/assets/images/icons/discover.svg"),
            },
          ],
        },
      ],
      category: null,
      category_name: null,
      category_des: null,
      active: null,
      queryInput: null,
    };
  },
  beforeMount() {
    this.getCategories();
  },
  mounted() {
    this.initializePage();
  },
  created() {
    window.addEventListener("scroll", this.showHeader);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showHeader);
  },
  methods: {
    async initializePage() {
      const urlParams = new URLSearchParams(window.location.search);
      const activityId = urlParams.get("id");

      if (activityId) {
        // Fetch posts for the given ID
        this.selected(activityId);
      } else {
        // Default behavior if no ID is provided
        this.selected(null);
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    showHeader() {
      this.isVisible = window.scrollY > 450;
      console.log(this.isVisible);
    },
    capitalizeFirstWord(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    toSearch() {
      this.$router.push({
        name: "results",
        params: { initialQuery: this.queryInput },
      });
    },
    subscribe() {
      if (this.$refs.form.validate()) {
        http.get(`https://balitripcenter.com/sanctum/csrf-cookie`).then(() => {
          axios
            .post("https://balitripcenter.com/api/subscriber", {
              email: this.email,
            })
            .then(() => {
              this.email = null;
            })
            .catch(() => {});
        });
      }
    },
    selected(id) {
      this.posts = [];
      this.loading = true;

      if (id == null) {
        // Default behavior for homepage
        this.currentPage = api;
        this.active = null;
        this.isPostShow = false;
        this.dynamicName = this.$t("discover");
        this.dynamicDescription = this.$t("discover_sub");
      } else {
        // Fetch data for the given category
        this.currentPage = `${api}/${id}`;
        this.active = id;
        this.isPostShow = true;

        const selectedCategory = this.categories.find(
          (category) => category.id === id
        );

        if (selectedCategory) {
          this.dynamicName = selectedCategory.name;
          this.dynamicDescription = selectedCategory.des;
        }
      }
      this.getPosts();
    },
    getCategories() {
      axios
        .get("https://balitripcenter.com/api/types")
        .then((response) => {
          this.categories = this.categories.concat(response.data.items);
        })
        .catch(() => {});
    },
    getPosts() {
      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.category = response.data.category;
          this.currentPage = response.data.items.next_page_url;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: { ActivityCardNew },
};
</script>

<style scoped>
.header-contanier {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 28px 16px 0px 16px;
}

.header-contanier2 {
  position: fixed !important;
  top: 48px;
  z-index: 10;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 8px 10px;
  border-radius: 0 0 28px 28px;
}

.header-contanier h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.header-image {
  height: 100%;
  width: 100%;
}

.search-container {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border-color: #eee;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.search-container input[type="text"] {
  font-size: 17px;
  overflow: hidden;
  border-radius: 10px;
  padding: 8px;
  color: #212121 !important;
  width: 100%;
  height: 50px;
  border: 1px solid #212121;
  text-indent: 30px;
}

/* placeholder color 212121 */
.search-container input[type="text"]::placeholder {
  color: #212121;
}

.search-container button {
  position: absolute;
  left: 0;
  padding: 8px;
  font-size: 17px;
  border: none;
  height: 100%;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}

.search-container button:hover {
  background: #ccc;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  min-width: 120px;
  background-color: #f6f6f6;
  margin: 0 8px;
  height: auto;
  min-height: 40px;
  padding: 4px 12px !important;
  font-weight: bold;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 5px !important;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #212121;
  scroll-snap-align: center;
}
.scrolling-wrapper .card.active {
  border-color: #18191b;
  background-color: #d9d9d9;
  padding: 4px 12px !important;
}

.header-contanier2 .scrolling-wrapper .card.active {
  border-color: #ff6100;
  background-color: #ffeee3;
  padding: 4px 12px !important;
}

.type-img {
  width: 40px;
  height: 40px;
  background: #fff;
  overflow: hidden;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
}

.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.input-group {
  border-radius: 4px;
  margin-top: 12px;
  position: relative;
  height: 40px;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  background: white;
  width: 100%;
}

.button {
  border-radius: 0 4px 4px 0;
  background-color: black;
  padding: 8px 12px;
  position: absolute;
  height: 100%;
  right: 0;
  color: white;
}

.input {
  padding: 8px;
  margin: 0px;
  width: 80%;
  height: 100%;
  left: 0;
  position: absolute;
  background-color: white;
}

.category-title {
  padding: 8px;
  font-size: 18px;
  color: #222;
}

.box {
  background-color: #fff;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}

.category-title {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.see-all {
  color: #000;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 600;
}

.see-all:hover {
  text-decoration: none;
}

.see-all v-icon {
  font-size: 16px;
}

.hidden {
  display: none !important;
}
</style>
