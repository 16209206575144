<template>
  <div>
    <agile
      :dots="false"
      :autoplay="true"
      :navButtons="false"
      :pauseOnHover="true"
      :perView="1"
    >
      <div v-for="(banner, index) in banners" :key="index" class="slide">
        <img :src="banner.original" :alt="`Banner ${index + 1}`" />
      </div>
    </agile>
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <div class="pa-0">
            <div class="gradient-hero">
              <div class="search-container">
                <!-- Updated search input with icon on the left -->
                <div class="search-input">
                  <img
                    src="@/assets/images/icons/search-black.svg"
                    class="search-icon"
                    alt="Search Icon"
                  />
                  <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Let's start your journey with balitripcenter"
                    v-on:keyup.enter="toSearch"
                  />
                </div>
              </div>
              <main-card></main-card>
            </div>

            <home-banner></home-banner>

            <home-recent-global></home-recent-global>

            <home-recommendations></home-recommendations>
            <divider></divider>

            <!-- <home-type></home-type>
            <divider></divider> -->
            <rental></rental>
            <divider></divider>
            <home-reviews></home-reviews>
            <divider></divider>
            <div id="gallery">
              <gallery-home></gallery-home>
            </div>
            <divider></divider>
            <!-- <video-gallery></video-gallery>
            <divider></divider> -->
            <video-bali></video-bali>
            <divider></divider>

            <home-tour></home-tour>
            <divider></divider>

            <home-populars></home-populars>
            <divider></divider>

            <home-water-sport></home-water-sport>
            <divider></divider>
            <honeymoon></honeymoon>
            <divider></divider>
            <home-feature></home-feature>
            <divider></divider>
            <transport-feature></transport-feature>
            <divider></divider>
            <home-specialoffer></home-specialoffer>
            <divider></divider>
            <home-about></home-about>
            <divider></divider>
            <loading :loading="loading"></loading>
            <home-login-card></home-login-card>
            <divider></divider>
            <home-event></home-event>
            <divider></divider>
            <v-flex xs12 text-center mt-2>
              <!-- <v-btn
                text
                class="img_bg"
                :to="{ name: 'destinations' }"
                >{{ $t('destinations')}}</v-btn
              ><br /> -->
              <div class="img_bg">{{ $t("destinations") }}</div>
              <span style="font-size: 14px; color: #000">{{
                $t("t-plan-your-tour-to", [$t("plan-your-tour-to")])
              }}</span>
            </v-flex>
            <home-destinations
              :destinations="items.destinations"
            ></home-destinations>
            <loading :loading="loading"></loading>
            <divider></divider>
            <v-flex xs12 text-center mt-2>
              <div class="header-text">{{ $t("traveler-informasi") }}</div>
              <div style="font-size: 14px">
                {{ $t("seputar-pengetahuan") }}
              </div>
              <br />
            </v-flex>
            <home-tips :tips="items.tips"></home-tips><br />
            <loading :loading="loading"></loading>
            <divider></divider>
            <home-categories :items="items"></home-categories><br />
            <div class="box">
              <v-layout>
                <v-flex xs12 items-center text-center>
                  <img
                    src="@/assets/images/icons/livechat-new.svg"
                    height="auto"
                    alt="$t('contact-us')"
                    style="margin: auto; width: 84px"
                  /><br />
                  <h4
                    class="text-h4"
                    style="
                      font-size: 14px !important;
                      text-align: center;
                      font-weight: 600;
                    "
                  >
                    Live Report
                  </h4>
                  <span class="caption">{{
                    $t("any_question_about_balitripcenter")
                  }}</span
                  ><br />
                  <a
                    class="email"
                    href="https://wa.me/6285857082012"
                    target="_blank"
                  >
                    <span class="caption">+62 85857082012</span> </a
                  ><br />
                  <a class="email" href="mailto:support@balitripcenter.com">
                    <span class="caption">support@balitripcenter.com</span>
                  </a>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Rental from "./Rental.vue";
import Honeymoon from "./Honeymoon.vue";
import HomeLoginCard from "./HomeLoginCard.vue";
import Divider from "../Divider.vue";
export default {
  components: { Rental, Honeymoon, HomeLoginCard, Divider },
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      searchQuery: "",
      banners: [],
    };
  },
  created() {
    this.fetchData();
    this.fetchBanners();
  },
  methods: {
    toSearch() {
      this.$router.push({
        name: "results",
        params: { initialQuery: this.searchQuery },
      });
    },
    selected(linkHref) {
      if (linkHref) {
        const element = document.querySelector(linKHref);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/index`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    fetchBanners() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/header`)
        .then((response) => {
          this.loading = false;
          this.banners = response.data.items;
          // console banner
          console.log(response.data.items);
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
  },
};
</script>
<style scoped>
.email,
:hover {
  text-decoration: none;
  color: black;
}

.gradient-hero {
  width: calc(100vw + 20px);
  padding: 10px 20px;
  margin-bottom: 5px;
  background: url("~@/assets/images/header-gradient.svg") no-repeat center
    center;
  background-size: cover;
  position: relative;
  top: -20px;
  left: -20px;
}

.clip {
  background-color: #ff6100;
}
.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border-bottom-right-radius: 50% 7%;
  border-bottom-left-radius: 50% 7%;
}

.box {
  /* background-color: #e7f2f8; */
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

/* Custom styles for the search input */
.search-input {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 15px 0;
}

.search-container {
  max-width: 600px;
  margin: auto auto;
}

.search-input input {
  width: 100%;
  padding-left: 40px; /* Space for the icon */
  padding-right: 20px;
  height: 40px;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}

.search-input .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.img_bg {
  background-color: #fd6301;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 8px;
  font-size: 14px;
  width: auto;
  object-position: center;
  color: white;
  display: block;
  margin-bottom: 8px;
}
.slide {
  width: 100vw;
  height: 100%;
  background-color: #fd6301;
  overflow: hidden; /* Ensures no scrollbars for the container */
}

.slide img {
  width: 100%; /* Adjusts the width to fill the screen */
  object-fit: contain; /* Ensures the image is fully visible without cropping */
}
</style>
