<template>
  <div class="bg-honeymoon">
    <div
      style="
        padding: 4px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div>
        <div
          class="body-2 hero-title"
          style="
            display: flex !important;
            align-items: center !important;
            gap: 5px !important;
          "
        >
          <img
            class="hero-icon"
            :src="category.images[0].original"
            alt="english"
          />
          <div class="hero-text">Honeymoon</div>
        </div>
      </div>

      <a
        href="activity?id=11"
        style="
          color: #fff;
          text-decoration: none;
          font-weight: 600;
          font-size: 14px;
        "
      >
        {{ $t("view_all") }}
      </a>
    </div>
    <div style="color: #fff">
      {{ $t("rayakan-hari-pernikahan-kamu") }}
    </div>
    <br />
    <div v-if="!loading">
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout row wrap>
            <v-flex v-for="(post, i) of items" :key="i" xs6>
              <home-activitiy-card
                class="card"
                :post="post"
              ></home-activitiy-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/type/11`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
          //console.log(response.data.category)
        })
        .catch((e) => {
          this.loading = false;
          // this.errors.push(e)
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.hero-title img {
  width: 30px;
  height: auto;
}

.hero-text {
  font-size: 16px;
  font-weight: 700;
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: #000;
}
.bg-honeymoon {
  background: url("~@/assets/images/banner-honeymoon.png") no-repeat center
    center;
  background-size: cover;
  color: #fff;
  padding: 16px 14px;
  border-radius: 9px;
  position: relative;
  width: 100vw;
  left: -12px;
}
.honeymoon {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.hero-title {
  margin: auto;
  padding: auto;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: #757575;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
