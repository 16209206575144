<template>
  <div>
    <div v-if="!loading">
      <div
        style="
          padding: 4px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          class=""
          style="font-size: 16px; font-weight: 700; margin-left: 5px"
        >
          {{ $t("most_activity") }}
        </div>

        <a
          href="activity"
          style="
            color: #000;
            text-decoration: none;
            font-weight: 500;
            font-size: 14px;
          "
        >
          {{ $t("view_all") }}
        </a>
      </div>

      <div
        v-if="items && items.length"
        style="margin-top: 20px; margin-left: 10px"
      >
        <div class="scrolling-wrapper">
          <div
            v-for="(post, index) in items"
            :key="index"
            class="card"
            style="min-width: 105px !important; max-width: 105px !important"
          >
            <div style="position: relative">
              <!-- Display thumbnail -->
              <img
                :src="post.images[0]?.thumbnail"
                alt="Thumbnail"
                style="border-radius: 8px; height: 110px; object-fit: cover"
              />

              <!-- Absolute badge -->
              <img
                src="@/assets/images/icons/medal.svg"
                alt="Badge"
                style="
                  position: absolute;
                  top: 0;
                  right: 10px;
                  width: 30px;
                  height: auto;
                "
              />
            </div>
            <!-- Display title -->
            <div
              style="
                text-align: center;
                font-weight: 600;
                margin-top: 10px;
                font-size: 12px !important;
              "
            >
              {{ truncateText(post.name, 17) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/type/1`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #000;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: 600;
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: gray;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
